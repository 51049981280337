export interface CreateEventResponse {
  room: {
    uuid: string;
    name: string;
    created_at: string;
    modified_at: string;
  };
}

export async function createV3Event(): Promise<CreateEventResponse> {
  const res = await fetch(
    `${process.env.REACT_APP_SOCKET_REST_URL}/createEvent`
  );

  if (res.status !== 200 && res.status !== 201) {
    const errorMessage = await res.text();
    throw new Error(errorMessage);
  }

  const result = await res.json();
  return result as CreateEventResponse;
}
