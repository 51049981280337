export interface UpdateEventLanguagesResponse {
  success: boolean;
}

export async function updateEventLanguages(
  eventId: string,
  targetLanguages: string[],
  setUpdatedLanguages: (languages: string[]) => void
): Promise<UpdateEventLanguagesResponse> {
  const timeout = new Promise<Response>((_, reject) =>
    setTimeout(() => reject(new Error("Request timeout after 5 seconds")), 5000)
  );

  const fetchRequest = fetch(
    `${process.env.REACT_APP_SOCKET_REST_URL}/updateEventLanguages?event_id=${eventId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ target_languages: targetLanguages }),
    }
  );

  const res = await Promise.race<Response>([fetchRequest, timeout]);

  if (res.status !== 200 && res.status !== 201) {
    throw new Error(String(res));
  }

  await setUpdatedLanguages(targetLanguages);
  return { success: true };
}
