import { Box, Paper, Typography } from "@mui/material";
import React, {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { useTranscriptionDisplay } from "../../../context/TranscriptionDisplay.tsx";
import PresentationSourceModal from "./PresentationSourceModal.tsx";
import "../RunMeeting.css";

interface Position {
  x: number;
  y: number;
}

const dummyCaption = "Click the orange “\u2009▸\u2009” button above to start.";

export default function RunMeetingCaptionOnly() {
  const {
    transcriptionDisplayTheme: theme,
    setTranscriptionPosition,
    transcriptionPosition,
    presentationSource,
    showPresentationSourceModal,
    transcriptionPositionType,
    setShowPresentationSourceModal,
  } = useTranscriptionDisplay();
  const { transcribedCaption, transcriptionStatus, isStale } =
    useTranscriptionContext();
  const [dragging, setDragging] = useState<boolean>(false);
  const [rel, setRel] = useState<Position | null>(null); // Stores the relative position of the mouse to the text element
  const textRef = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useLayoutEffect(() => {
    const parentRect = document
      .getElementById("caption-parent")
      ?.getBoundingClientRect();
    const textRect = document
      .getElementById("caption-text")
      ?.getBoundingClientRect();
    if (!parentRect || !textRect) return;

    // Calculate the position near the bottom
    const bottomPadding = 0; // Adjust this value to control distance from bottom
    const centerX = parentRect.left + (parentRect.width - textRect.width) / 2;
    const bottomY = parentRect.height - textRect.height - bottomPadding;

    // Ensure the text element doesn't go beyond the parent's edges
    const boundedX = Math.max(
      parentRect.left,
      Math.min(centerX, parentRect.right - textRect.width)
    );

    setTranscriptionPosition({
      x: boundedX,
      y: bottomY,
    });
  }, [setTranscriptionPosition]);

  useEffect(() => {
    if (transcriptionStatus === "started" && !presentationSource.sourceApp) {
      setShowPresentationSourceModal(false);
    }
  }, [
    transcriptionStatus,
    presentationSource.sourceApp,
    setShowPresentationSourceModal,
  ]);

  useEffect(() => {
    if (!textRef.current) return;
    textRef.current.scrollTop = textRef.current.scrollHeight;
  }, [transcribedCaption]);

  useEffect(() => {
    if (!textContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!parentRef.current || !textContainerRef.current) return;
      const parentRect = parentRef.current.getBoundingClientRect();
      const textRect = textContainerRef.current.getBoundingClientRect();
      if (transcriptionPosition.y + textRect.height > parentRect.bottom) {
        setTranscriptionPosition({
          x: transcriptionPosition.x,
          y: parentRect.bottom - textRect.height,
        });
      }
    });

    resizeObserver.observe(textContainerRef.current);
    return () => resizeObserver.disconnect();
  }, [transcriptionPosition, setTranscriptionPosition]);

  useEffect(() => {
    if (
      transcriptionStatus === "started" &&
      presentationSource.sourceUrl &&
      iframeRef.current
    ) {
      iframeRef.current.focus();
    }
  }, [transcriptionStatus, presentationSource.sourceUrl]);

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (e.button !== 0) return; // Only allow left mouse button
    if (textContainerRef.current) {
      const textRect = textContainerRef.current.getBoundingClientRect();
      setRel({
        x: e.pageX - textRect.left,
        y: e.pageY - textRect.top,
      });
      setDragging(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const svgFilter = (
    <svg width="0" height="0" style={{ position: "absolute" }}>
      <defs>
        <filter id="text-outline">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="1"
          ></feMorphology>
          <feFlood
            floodColor="#000000"
            floodOpacity="1"
            result="COLORED"
          ></feFlood>
          <feComposite
            in="COLORED"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  );

  const allStyles = React.useMemo(() => {
    // Whole container styles
    const wholeContainerStyles = {
      width: "100%",
      height: "100%",
      maxHeight: "100vh",
      position: "relative",
      backgroundColor:
        theme.captionBackgroundType === "black"
          ? `rgba(0,0,0, ${
              theme.captioBackgroundOpacity ||
              theme.captioBackgroundOpacity === 0
                ? theme.captioBackgroundOpacity
                : 1
            })`
          : theme.backgroundColor,
      display: "flex",
      flexDirection:
        transcriptionPositionType === "floating"
          ? "unset"
          : transcriptionPositionType === "fixed-bottom"
          ? "column"
          : "column-reverse",
      alignItems: transcriptionPositionType === "floating" ? "unset" : "center",
    };
    // slides container styles
    const slidesContainerStyles = {
      backgroundColor: theme.backgroundColor,
      fontSize:
        theme.fontSize === "small"
          ? "3.25vh"
          : theme.fontSize === "medium"
          ? "4.25vh"
          : "6vh",
      width: "100%",
      height:
        transcriptionPositionType === "floating"
          ? "100%"
          : "calc(100% - 3.6em)",
      maxHeight: "100vh",
      position: "relative",
    };

    // caption container styles
    const captionContainerStyles = {
      backgroundColor:
        transcriptionPositionType === "floating"
          ? theme.captionBackgroundType === "black"
            ? `rgba(0,0,0, ${
                theme.captioBackgroundOpacity ||
                theme.captioBackgroundOpacity === 0
                  ? theme.captioBackgroundOpacity
                  : 1
              })`
            : theme.backgroundColor
          : "transparent",
      position: transcriptionPositionType === "floating" ? "absolute" : "unset",
      fontFamily:
        theme.fontFamily === "serif"
          ? "Martel, Noto Serif SC, Noto Serif KR, Noto Sans Math, serif"
          : "AR One Sans, Noto Sans SC, Noto Sans KR, Noto Sans Math, sans-serif",
      fontSize:
        theme.fontSize === "small"
          ? "3.25vh"
          : theme.fontSize === "medium"
          ? "4.25vh"
          : "6vh",
      color: theme.fontColor,
      wordSpacing: "0.1em",
      lineHeight: "1.5em",
      fontWeight: theme.fontFamily === "serif" ? "600" : "400",
      width: transcriptionPositionType === "floating" ? "23em" : "100vw",
      marginTop: "0em",
      // transcriptionPositionType === "fixed-bottom" &&
      // navigator.userAgent.includes("Safari") &&
      // !navigator.userAgent.includes("Chrome")
      //   ? "0.15em"
      //   : transcriptionPositionType === "fixed-top" &&
      //     navigator.userAgent.includes("Safari") &&
      //     !navigator.userAgent.includes("Chrome")
      //   ? "0em"
      //   : "0.3em",
      marginBottom: "0em",
      // transcriptionPositionType === "fixed-top" &&
      // navigator.userAgent.includes("Safari") &&
      // !navigator.userAgent.includes("Chrome")
      //   ? "0.3em"
      //   : "unset",
      // margin: '0em',
      // borderRadius:
      //   transcriptionPositionType === "floating" ? "calc(0.7em - 0.5ex)" : "0",
      padding: "0.3em 0.6em 0.3em 0.6em",
      left:
        transcriptionPositionType === "floating"
          ? transcriptionPosition.x
          : "0",
      top:
        transcriptionPositionType === "floating"
          ? transcriptionPosition.y
          : "unset",
      bottom: transcriptionPositionType === "fixed-bottom" ? "0" : "unset",
      cursor:
        transcriptionPositionType !== "floating"
          ? "unset"
          : dragging
          ? "grabbing"
          : "grab",
      boxShadow: theme.dropShadow
        ? "5px 5px 20px 0px rgba(0, 0, 0, 0.601)"
        : "none",
      visibility: showPresentationSourceModal ? "hidden" : "visible",
    };

    return {
      wholeContainerStyles,
      slidesContainerStyles,
      captionContainerStyles,
    };
  }, [
    transcriptionPositionType,
    transcriptionPosition,
    theme,
    dragging,
    showPresentationSourceModal,
  ]);

  const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging || !rel || !parentRef.current || !textContainerRef.current)
      return;

    const parentRect = parentRef.current.getBoundingClientRect();
    const textRect = textContainerRef.current.getBoundingClientRect();

    let newX = e.pageX - rel.x;
    let newY = e.pageY - rel.y;

    // Boundary checks
    if (newX < parentRect.left) newX = parentRect.left;
    if (newY < parentRect.top) newY = parentRect.top;
    if (newX + textRect.width > parentRect.right)
      newX = parentRect.right - textRect.width;
    if (newY + textRect.height > parentRect.bottom)
      newY = parentRect.bottom - textRect.height;

    setTranscriptionPosition({
      x: newX - parentRect.left,
      y: newY - parentRect.top,
    });

    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Box
      id="caption-parent"
      ref={parentRef}
      sx={allStyles.wholeContainerStyles}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {svgFilter}

      {showPresentationSourceModal && (
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 300,
            width: "50%",
          }}
        >
          <PresentationSourceModal
            onCancel={() => setShowPresentationSourceModal(false)}
            onDone={() => setShowPresentationSourceModal(false)}
          />
        </Paper>
      )}
      <Box sx={allStyles.slidesContainerStyles}>
        <iframe
          ref={iframeRef}
          title="slidePlayer"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            display: "inline",
          }}
          src={presentationSource.sourceUrl}
        ></iframe>
        {
          // Used to cover the bottom bar of Google Slides
          presentationSource.sourceApp === "googleslides" && (
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                height: "36px",
                width: "100%",
                backgroundColor: "#000000",
                zIndex: 100,
              }}
            />
          )
        }
        {/* https://www.canva.com/design/DAGCD3yc_sU/eWFSTQWaULosAyGgIz0arw/view?embed */}
      </Box>
      <Box
        id="caption-text"
        ref={textContainerRef}
        onMouseDown={onMouseDown}
        sx={allStyles.captionContainerStyles}
      >
        <Typography
          ref={textRef}
          sx={{
            wordSpacing: "inherit",
            lineHeight: "inherit",
            fontWeight: "inherit",
            fontFamily: "inherit",
            fontSize: "inherit",
            backgroundColor: "transparent",
            color:
              transcriptionStatus === "paused" || isStale
                ? "#fdc607"
                : "inherit",
            scrollBehavior: "smooth",
            overflow: "hidden",
            minHeight: "3em",
            maxHeight: "3em",
            whiteSpace: "normal",
            textAlign:
              transcriptionStatus === "paused" || isStale
                ? "center"
                : "inherit",
            display: "flex",
            alignItems: "top",
            justifyContent:
              transcriptionStatus === "paused" || isStale
                ? "center"
                : "flex-start",
            filter: "url(#text-outline)",
          }}
        >
          {transcriptionStatus === "unstarted" &&
          (!transcribedCaption || transcribedCaption === " ")
            ? dummyCaption
            : transcriptionStatus === "paused" || isStale
            ? "Live captions provided by Sunflower AI"
            : transcribedCaption}
        </Typography>
      </Box>
      {showPresentationSourceModal && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.backgroundColor,
          }}
        />
      )}
    </Box>
  );
}
