import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  // List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Divider,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
import { updateEventLanguages } from "../../../services/UpdateEventLanguages.ts";

export const languageOptions = new Map<string, string>([
  ["en-AU", "English (AU)"],
  ["en-US", "English (US)"],
  ["zh-CN", "Mandarin"],
  ["ar-SA", "Arabic"],
  ["zh-HK", "Cantonese"],
  ["vi-VN", "Vietnamese"],
  ["it-IT", "Italian"],
  ["el-GR", "Greek"],
  ["hi-IN", "Hindi"],
  ["es-ES", "Spanish"],
  ["pa-IN", "Punjabi"],
  ["af-ZA", "Afrikaans"],
  ["sq-AL", "Albanian"],
  ["am-ET", "Amharic"],
  ["hy-AM", "Armenian"],
  ["az-AZ", "Azerbaijani"],
  ["eu-ES", "Basque"],
  ["bn-IN", "Bengali"],
  ["bs-BA", "Bosnian"],
  ["bg-BG", "Bulgarian"],
  ["my-MM", "Burmese"],
  ["ca-ES", "Catalan"],
  ["hr-HR", "Croatian"],
  ["cs-CZ", "Czech"],
  ["da-DK", "Danish"],
  ["nl-NL", "Dutch"],
  ["et-EE", "Estonian"],
  ["fi-FI", "Finnish"],
  ["fil-PH", "Filipino"],
  ["fr-FR", "French"],
  ["gl-ES", "Galician"],
  ["ka-GE", "Georgian"],
  ["de-DE", "German"],
  ["el-GR", "Greek"],
  ["gu-IN", "Gujarati"],
  ["he-IL", "Hebrew"],
  ["hu-HU", "Hungarian"],
  ["is-IS", "Icelandic"],
  ["id-ID", "Indonesian"],
  ["ga-IE", "Irish"],
  ["ja-JP", "Japanese"],
  ["jv-ID", "Javanese"],
  ["kn-IN", "Kannada"],
  ["kk-KZ", "Kazakh"],
  ["km-KH", "Khmer"],
  ["ko-KR", "Korean"],
  ["lo-LA", "Lao"],
  ["lv-LV", "Latvian"],
  ["lt-LT", "Lithuanian"],
  ["mk-MK", "Macedonian"],
  ["ms-MY", "Malay"],
  ["ml-IN", "Malayalam"],
  ["mt-MT", "Maltese"],
  ["mr-IN", "Marathi"],
  ["mn-MN", "Mongolian"],
  ["ne-NP", "Nepali"],
  ["nb-NO", "Norwegian"],
  ["ps-AF", "Pashto"],
  ["fa-IR", "Persian"],
  ["pl-PL", "Polish"],
  ["pt-BR", "Portuguese"],
  ["ro-RO", "Romanian"],
  ["ru-RU", "Russian"],
  ["sr-RS", "Serbian"],
  ["si-LK", "Sinhala"],
  ["sk-SK", "Slovak"],
  ["sl-SI", "Slovenian"],
  ["so-SO", "Somali"],
  ["sw-KE", "Swahili"],
  ["sv-SE", "Swedish"],
  ["ta-IN", "Tamil"],
  ["te-IN", "Telugu"],
  ["th-TH", "Thai"],
  ["tr-TR", "Turkish"],
  ["uk-UA", "Ukrainian"],
  ["ur-IN", "Urdu"],
  ["uz-UZ", "Uzbek"],
  ["cy-GB", "Welsh"],
  ["zu-ZA", "Zulu"],
]);
export default function HeaderLanguageSection() {
  const {
    setSourceLanguage,
    setTargetLanguage,
    languageSettings,
    isTranscribing,
    currentEvent,
    setUpdatedLanguages,
  } = useTranscriptionContext();
  const [fromLanguageElement, setFromLanguageElement] =
    React.useState<null | HTMLElement>(null);
  const fromLanguageOpen = Boolean(fromLanguageElement);
  const [toLanguageElement, setToLanguageElement] =
    React.useState<null | HTMLElement>(null);
  const toLanguageOpen = Boolean(toLanguageElement);
  const theme = useTheme();
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [moreLanguagesAnchor, setMoreLanguagesAnchor] =
    React.useState<null | HTMLElement>(null);
  const [selectedCustomLanguages, setSelectedCustomLanguages] = React.useState<
    Set<string>
  >(() => {
    return new Set<string>();
  });
  const [deselectedAvailableLanguages, setDeselectedAvailableLanguages] =
    React.useState<Set<string>>(() => {
      return new Set<string>();
    });
  const [tempDeselectedLanguages, setTempDeselectedLanguages] = React.useState<
    Set<string>
  >(new Set());
  const [tempSelectedLanguages, setTempSelectedLanguages] = React.useState<
    Set<string>
  >(new Set());
  const MAX_LANGUAGES = 15;
  const [cancelConfirmOpen, setCancelConfirmOpen] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const handleClickFromLanguageListItem = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (!isTranscribing) {
      setFromLanguageElement(event.currentTarget);
    }
  };

  const handleFromLanguageMenuItemClick = (option: string) => {
    if (!isTranscribing) {
      setSourceLanguage(option);
      setFromLanguageElement(null);
    }
  };

  const handleClose = () => {
    setFromLanguageElement(null);
  };

  const handleClickToLanguageListItem = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (!isTranscribing) {
      setToLanguageElement(event.currentTarget);
    }
  };

  const handleToLanguageMenuItemClick = (option: string) => {
    if (!isTranscribing) {
      setTargetLanguage(option);
      setToLanguageElement(null);
    }
  };

  const handleCloseToLanguage = () => {
    setToLanguageElement(null);
  };

  const handleSwapLanguages = () => {
    if (!isTranscribing) {
      const temp = languageSettings.source;
      setSourceLanguage(languageSettings.target);
      setTargetLanguage(temp);
    }
  };

  const disabledStyle = isTranscribing ? { cursor: "not-allowed" } : {};

  const getAllLanguageOptions = (
    availableLanguages: string[],
    currentLanguage: string
  ) => {
    const availableMap = new Map(
      availableLanguages.map((lang, index) => [lang, index])
    );

    const allOptions = Array.from(languageOptions.keys()).map((langCode) => ({
      code: langCode,
      name: languageOptions.get(langCode) || "",
      available: availableMap.has(langCode),
      selected: langCode === currentLanguage,
      sortIndex: availableMap.get(langCode) ?? Number.MAX_SAFE_INTEGER,
    }));

    return allOptions.sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleCustomLanguageToggle = (code: string, isAvailable: boolean) => {
    if (code === "en-AU") return;

    if (isAvailable) {
      setTempDeselectedLanguages((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(code)) {
          newSet.delete(code);
        } else {
          newSet.add(code);
        }
        return newSet;
      });
    } else {
      setTempSelectedLanguages((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(code)) {
          newSet.delete(code);
        } else {
          newSet.add(code);
        }
        return newSet;
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleConfirm = async () => {
    if (!currentEvent?.uuid) return;

    setIsUpdating(true);
    try {
      // Get all selected languages (available ones that aren't deselected + custom selected ones)
      const allSelectedLanguages = Array.from(
        new Set([
          ...(currentEvent.target_languages || []).filter(
            (lang) => !tempDeselectedLanguages.has(lang)
          ),
          ...Array.from(tempSelectedLanguages),
        ])
      );

      // Call the API with the new signature
      const response = await updateEventLanguages(
        currentEvent.uuid,
        allSelectedLanguages,
        setUpdatedLanguages
      );

      if (response.success) {
        // Update local state
        setDeselectedAvailableLanguages(tempDeselectedLanguages);
        setSelectedCustomLanguages(tempSelectedLanguages);

        // Show success message
        setSnackbar({
          open: true,
          message: "Languages updated successfully",
          severity: "success",
        });

        // Close the menu
        setMoreLanguagesAnchor(null);
      } else {
        throw new Error("Failed to update languages");
      }
    } catch (error) {
      console.error("Failed to update languages:", error);
      setSnackbar({
        open: true,
        message: "Failed to update languages. Please try again.",
        severity: "error",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const hasUnsavedChanges = () => {
    return (
      !areSetsEqual(tempDeselectedLanguages, deselectedAvailableLanguages) ||
      !areSetsEqual(tempSelectedLanguages, selectedCustomLanguages)
    );
  };

  const handleCancel = () => {
    if (hasUnsavedChanges()) {
      setCancelConfirmOpen(true);
    } else {
      handleCancelConfirmed();
    }
  };

  const handleCancelConfirmed = () => {
    setTempDeselectedLanguages(deselectedAvailableLanguages);
    setTempSelectedLanguages(selectedCustomLanguages);
    setMoreLanguagesAnchor(null);
    setCancelConfirmOpen(false);
  };

  const areSetsEqual = (set1: Set<string>, set2: Set<string>) => {
    if (set1.size !== set2.size) return false;
    return Array.from(set1).every((item) => set2.has(item));
  };

  React.useEffect(() => {
    if (moreLanguagesAnchor) {
      setTempDeselectedLanguages(new Set(deselectedAvailableLanguages));
      setTempSelectedLanguages(new Set(selectedCustomLanguages));
    }
  }, [
    moreLanguagesAnchor,
    deselectedAvailableLanguages,
    selectedCustomLanguages,
  ]);

  const handleMenuClose = () => {
    if (hasUnsavedChanges()) {
      setCancelConfirmOpen(true);
    } else {
      setMoreLanguagesAnchor(null);
    }
  };

  const renderMenuItems = (
    options: ReturnType<typeof getAllLanguageOptions>,
    handleClick: (code: string) => void,
    needMoreLanguages: boolean
  ) => {
    const availableOptions = options.filter(
      (opt) =>
        (opt.available && !tempDeselectedLanguages.has(opt.code)) ||
        tempSelectedLanguages.has(opt.code)
    );

    const selectedCount = availableOptions.length;

    const menuItems = needMoreLanguages
      ? [
          <div id="submenu-anchor" key="submenu-anchor" />,
          ...availableOptions.map(({ code, name, selected }) => (
            <MenuItem
              key={code}
              selected={selected}
              onClick={() => handleClick(code)}
              disabled={isTranscribing}
            >
              {name}
            </MenuItem>
          )),

          <Divider key="divider" />,

          <MenuItem
            key="more-languages"
            onClick={() => {
              const submenuAnchor = document.getElementById("submenu-anchor");
              setMoreLanguagesAnchor(submenuAnchor);
            }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>More Languages</span>
            <Typography
              variant="caption"
              sx={{
                ml: 2,
                px: 1,
                py: 0.5,
                borderRadius: 1,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color:
                  selectedCount >= MAX_LANGUAGES ? "error.main" : "inherit",
              }}
            >
              {selectedCount}/{MAX_LANGUAGES}
            </Typography>
          </MenuItem>,

          <Menu
            key="submenu"
            anchorEl={moreLanguagesAnchor}
            open={Boolean(moreLanguagesAnchor)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disableAutoFocusItem
            PaperProps={{
              sx: {
                maxHeight: "60vh",
                marginLeft: "8px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden", // Prevent outer scrollbar
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "rgba(255, 255, 255, 0.3)",
                },
                scrollbarWidth: "thin",
                scrollbarColor: "rgba(255, 255, 255, 0.2) transparent",
              },
            }}
          >
            <div
              style={{
                overflowY: "auto",
                maxHeight: "calc(60vh - 64px)", // Subtract footer height
                flex: "1 1 auto",
              }}
            >
              {options
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ code, name, available }) => (
                  <MenuItem
                    key={code}
                    onClick={() => handleCustomLanguageToggle(code, available)}
                    disabled={
                      isTranscribing ||
                      code === "en-AU" ||
                      (!tempSelectedLanguages.has(code) &&
                        !available &&
                        selectedCount >= MAX_LANGUAGES)
                    }
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      checked={
                        code === "en-AU" ||
                        (available && !tempDeselectedLanguages.has(code)) ||
                        tempSelectedLanguages.has(code)
                      }
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => {
                        if (
                          code !== "en-AU" &&
                          (selectedCount < MAX_LANGUAGES ||
                            tempSelectedLanguages.has(code) ||
                            available)
                        ) {
                          handleCustomLanguageToggle(code, available);
                        }
                      }}
                      disabled={
                        isTranscribing ||
                        code === "en-AU" ||
                        (!tempSelectedLanguages.has(code) &&
                          !available &&
                          selectedCount >= MAX_LANGUAGES)
                      }
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </div>

            <div
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "transparent", // Make background transparent
                borderTop: `1px solid ${theme.palette.divider}`,
                padding: "8px",
                zIndex: 1,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  onClick={handleCancel}
                  sx={{
                    minWidth: "80px",
                    color: theme.palette.text.primary,
                    paddingTop: "8px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleConfirm}
                  disabled={isUpdating}
                  sx={{
                    minWidth: "80px",
                  }}
                >
                  {isUpdating ? "Updating..." : "Confirm"}
                </Button>
              </Stack>
            </div>
          </Menu>,
        ]
      : [
          ...availableOptions.map(({ code, name, selected }) => (
            <MenuItem
              key={code}
              selected={selected}
              onClick={() => handleClick(code)}
              disabled={isTranscribing}
            >
              {name}
            </MenuItem>
          )),
        ];
    return menuItems;
  };

  return (
    <RunMeetingHeaderSection label="Language">
      <Stack direction="row" alignItems="center" spacing={0}>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <div>
            <ListItemButton
              sx={{
                padding: 0.2,
                paddingLeft: 1.5,
                paddingRight: 1.5,
                borderRadius: "40px",
                justifyContent: "flex-end",
                border: `1px solid ${theme.palette.divider}`,
                "&:hover": {
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
              onClick={handleClickFromLanguageListItem}
              disabled={isTranscribing}
              aria-controls={
                fromLanguageOpen ? "from-language-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={fromLanguageOpen}
            >
              <ListItemText
                primary={
                  <Typography noWrap>
                    {languageOptions.get(languageSettings.source)}
                  </Typography>
                }
                sx={{ textAlign: "right" }}
              />
            </ListItemButton>
          </div>
        </Tooltip>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <IconButton
            onClick={handleSwapLanguages}
            size="small"
            disabled={isTranscribing}
            sx={disabledStyle}
            aria-label="Swap languages"
          >
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <div>
            <ListItemButton
              sx={{
                padding: 0.2,
                paddingLeft: 1.5,
                paddingRight: 1.5,
                borderRadius: "40px",
                border: `1px solid ${theme.palette.divider}`,
                "&:hover": {
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
              onClick={handleClickToLanguageListItem}
              disabled={isTranscribing}
              aria-controls={toLanguageOpen ? "to-language-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={toLanguageOpen}
            >
              <ListItemText
                primary={
                  <Typography noWrap>
                    {languageOptions.get(languageSettings.target)}
                  </Typography>
                }
              />
            </ListItemButton>
          </div>
        </Tooltip>
      </Stack>
      <Menu
        id="from-language-menu"
        anchorEl={fromLanguageElement}
        open={fromLanguageOpen}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
          "aria-label": "Select source language",
        }}
      >
        {renderMenuItems(
          getAllLanguageOptions(
            currentEvent?.source_language.split(",") || [],
            languageSettings.source
          ),
          handleFromLanguageMenuItemClick,
          false
        )}
      </Menu>
      <Menu
        id="to-language-menu"
        anchorEl={toLanguageElement}
        open={toLanguageOpen}
        onClose={handleCloseToLanguage}
        MenuListProps={{
          role: "listbox",
          "aria-label": "Select target language",
        }}
      >
        {renderMenuItems(
          getAllLanguageOptions(
            currentEvent?.target_languages || [],
            languageSettings.target
          ),
          handleToLanguageMenuItemClick,
          true
        )}
      </Menu>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={cancelConfirmOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setCancelConfirmOpen(false);
          }
        }}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">Discard Changes?</DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            All the language changes you made will NOT be saved. Are you sure
            you want to discard these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            pb: 2,
          }}
        >
          <Button
            onClick={() => setCancelConfirmOpen(false)}
            size="small"
            sx={{ color: "warning.main" }}
          >
            NO, KEEP EDITING
          </Button>
          <Button
            onClick={handleCancelConfirmed}
            color="primary"
            variant="contained"
            size="small"
            autoFocus
          >
            YES, DISCARD CHANGES
          </Button>
        </DialogActions>
      </Dialog>
    </RunMeetingHeaderSection>
  );
}
