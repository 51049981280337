import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { languageOptions } from "../../RunMeeting/components/HeaderTranscriptionLanguage.tsx";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { isDesktop } from "react-device-detect";

type Props = {
  open: boolean;
  onLanguageSelect: (lang: string) => void;
  selectedLanguage?: string;
  onClose: () => void;
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const nativeLanguageNames = new Map<string, string>([
  ["zh-CN", "中文"],
  ["ar-SA", "العربية"],
  ["vi-VN", "Tiếng Việt"],
  ["it-IT", "Italiano"],
  ["el-GR", "Ελληνικά"],
  ["hi-IN", "हिन्दी"],
  ["es-ES", "Español"],
  ["pa-IN", "ਪੰਜਾਬੀ"],
  ["af-ZA", "Afrikaans"],
  ["sq-AL", "Shqip"],
  ["am-ET", "አማርኛ"],
  ["hy-AM", "Հայերեն"],
  ["az-AZ", "Azərbaycan"],
  ["eu-ES", "Euskara"],
  ["bn-IN", "বাংলা"],
  ["bs-BA", "Bosanski"],
  ["bg-BG", "Български"],
  ["my-MM", "မြန်မာ"],
  ["ca-ES", "Català"],
  ["hr-HR", "Hrvatski"],
  ["cs-CZ", "Čeština"],
  ["da-DK", "Dansk"],
  ["nl-NL", "Nederlands"],
  ["et-EE", "Eesti"],
  ["fi-FI", "Suomi"],
  ["fil-PH", "Filipino"],
  ["fr-FR", "Français"],
  ["gl-ES", "Galego"],
  ["ka-GE", "ქართული"],
  ["de-DE", "Deutsch"],
  ["gu-IN", "ગુજરાતી"],
  ["he-IL", "עברית"],
  ["hu-HU", "Magyar"],
  ["is-IS", "Íslenska"],
  ["id-ID", "Bahasa Indonesia"],
  ["ga-IE", "Gaeilge"],
  ["ja-JP", "日本語"],
  ["jv-ID", "Basa Jawa"],
  ["kn-IN", "ಕನ್ನಡ"],
  ["kk-KZ", "Қазақ"],
  ["km-KH", "ខ្មែរ"],
  ["ko-KR", "한국어"],
  ["lo-LA", "ລາວ"],
  ["lv-LV", "Latviešu"],
  ["lt-LT", "Lietuvių"],
  ["mk-MK", "Македонски"],
  ["ms-MY", "Bahasa Melayu"],
  ["ml-IN", "മലയാളം"],
  ["mt-MT", "Malti"],
  ["mr-IN", "मराठी"],
  ["mn-MN", "Монгол"],
  ["ne-NP", "नेपाली"],
  ["nb-NO", "Norsk"],
  ["ps-AF", "پښتو"],
  ["fa-IR", "فارسی"],
  ["pl-PL", "Polski"],
  ["pt-BR", "Português"],
  ["ro-RO", "Română"],
  ["ru-RU", "Русский"],
  ["sr-RS", "Српски"],
  ["si-LK", "සිංහල"],
  ["sk-SK", "Slovenčina"],
  ["sl-SI", "Slovenščina"],
  ["so-SO", "Soomaali"],
  ["sw-KE", "Kiswahili"],
  ["sv-SE", "Svenska"],
  ["ta-IN", "தமிழ்"],
  ["te-IN", "తెలుగు"],
  ["th-TH", "ไทย"],
  ["tr-TR", "Türkçe"],
  ["uk-UA", "Українська"],
  ["ur-IN", "اردو"],
  ["uz-UZ", "O'zbek"],
  ["cy-GB", "Cymraeg"],
  ["zu-ZA", "IsiZulu"],
]);

export default function SubscriberLanguageModal({
  open,
  selectedLanguage,
  onLanguageSelect,
  onClose,
}: Props) {
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
  });
  const { currentEvent } = useTranscriptionContext();

  const allLanguageOptions = React.useMemo(() => {
    if (!currentEvent) return [];

    // Convert available languages to Set for faster lookup
    const availableSet = new Set(currentEvent.target_languages);

    // Only get available languages
    return Array.from(languageOptions.entries())
      .filter(([code]) => availableSet.has(code))
      .map(([code, name]) => ({
        label: `${name} - ${nativeLanguageNames.get(code) || name}`,
        id: code,
        available: true,
      }))
      .sort((a, b) => {
        // Maintain original order from target_languages
        return (
          currentEvent.target_languages.indexOf(a.id) -
          currentEvent.target_languages.indexOf(b.id)
        );
      });
  }, [currentEvent]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLanguageSelect = React.useCallback(
    (newValue: { label: string; id: string; available: boolean } | null) => {
      if (!newValue) return;

      if (newValue.available) {
        onLanguageSelect(newValue.id);
        localStorage.setItem("selectedLanguage", JSON.stringify(newValue));
      } else {
        setSnackbar({
          open: true,
          message: `Support for ${newValue.label} is coming soon!`,
        });
      }
    },
    [setSnackbar, onLanguageSelect]
  );

  React.useEffect(() => {
    if (!currentEvent) return;
    const localSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (!localSelectedLanguage) return;
    const localLanguageParsed = JSON.parse(localSelectedLanguage);
    const matchedLanguageOption =
      allLanguageOptions.find(
        (option) => option.id === localLanguageParsed.id
      ) || null;

    if (!matchedLanguageOption || !matchedLanguageOption.available) {
      return;
    }
    handleLanguageSelect(matchedLanguageOption);
  }, [handleLanguageSelect, allLanguageOptions, currentEvent]);

  const handleClose = () => onClose();

  return (
    <Modal
      open={open}
      disableAutoFocus
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom="20px"
        >
          Select a language
        </Typography>
        {isDesktop ? (
          <Autocomplete
            value={
              allLanguageOptions.find((lang) => lang.id === selectedLanguage) ??
              allLanguageOptions[0] ??
              null
            }
            fullWidth
            onChange={(event, newValue) => handleLanguageSelect(newValue)}
            options={allLanguageOptions}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  py: 1,
                }}
              >
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Language"
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    "& .MuiAutocomplete-input": {
                      whiteSpace: "normal",
                      lineHeight: 1.4,
                    },
                  },
                }}
              />
            )}
          />
        ) : (
          <Select
            labelId="language-select-label"
            value={selectedLanguage || "en-AU"}
            fullWidth
            onChange={(event) =>
              handleLanguageSelect(
                allLanguageOptions.find(
                  (lang) => lang.id === event.target.value
                ) ?? null
              )
            }
            displayEmpty
            sx={{
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                wordBreak: "break-word",
              },
            }}
          >
            {allLanguageOptions.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  py: 1,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity="info">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}
